import React, { useEffect, useState, useCallback } from 'react';
import { MenuData, MenuDescription } from '../../../types/menu';

import Radio from '../../../components/common/Radio';
import Checkbox from '../../../components/common/Checkbox';
import TextArea from '../../../components/common/TextArea';
import Button from '../../../components/common/Button';
import PageTitle from '../../../components/common/PageTitle';
import Toggle from '../../../components/common/Toggle';

interface GenerateFormProps {
  menuData: MenuData;
  formData: Record<string, string | string[]>;
  errors: Record<string, string>;
  handleInputChange: (name: string, value: string | string[]) => void;
  onSubmit: () => void;
}

const GenerateForm: React.FC<GenerateFormProps> = ({
  menuData,
  formData,
  errors,
  handleInputChange,
  onSubmit,
}) => {
  // 各項目の表示/非表示状態を管理するstate
  const [visibleItems, setVisibleItems] = useState<Record<string, boolean>>({});

  // visibleItemsを更新する関数
  const updateVisibleItems = useCallback(() => {
    const newVisibleItems: Record<string, boolean> = {};
    menuData.menu_descriptions.forEach((desc) => {
      // ルールがある場合、親項目の値に基づいて表示/非表示を決定
      if (desc.rules) {
        desc.rules.forEach((rule) => {
          const parentValue = formData[rule.parent_description_name];
          newVisibleItems[desc.name + '-' + rule.item_name] =
            parentValue === rule.parent_description_item;
        });
      } else {
        // ルールがない場合は常に表示
        newVisibleItems[desc.name] = true;
      }
    });
    setVisibleItems(newVisibleItems);
  }, [formData, menuData.menu_descriptions]);

  // formDataが変更されたときにvisibleItemsを更新
  useEffect(() => {
    updateVisibleItems();
  }, [formData, updateVisibleItems]);

  // visibleItemsが変更されたときに、非表示項目のチェックを外す
  useEffect(() => {
    menuData.menu_descriptions.forEach((desc) => {
      if (desc.type === 'multiselect' && Array.isArray(formData[desc.name])) {
        const currentItems = formData[desc.name] as string[];
        // 表示されている項目のみをフィルタリング
        const newSelectedItems = currentItems.filter(
          (item) => visibleItems[`${desc.name}-${item}`] !== false
        );

        // 選択項目が変更された場合、formDataを更新
        if (newSelectedItems.length !== currentItems.length) {
          handleInputChange(desc.name, newSelectedItems);
        }
      }
    });
  }, [visibleItems, formData, menuData.menu_descriptions, handleInputChange]);

  // フォーム送信時の処理
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    onSubmit();
  };

  // 各フィールドタイプに応じたレンダリング処理
  const renderField = (desc: MenuDescription) => {
    switch (desc.type) {
      case 'select':
        return (
          <div className="-mx-2 flex flex-wrap">
            {desc.items.map((item) => (
              <div key={item} className="mb-2">
                <Radio
                  label={<span className="inline-block w-[95px] text-14 text-black-g">{item}</span>}
                  name={desc.name}
                  value={item}
                  checked={formData[desc.name] === item}
                  onChange={() => handleInputChange(desc.name, item)}
                />
              </div>
            ))}
          </div>
        );
      case 'multiselect':
        return (
          <div className="grid grid-cols-3 gap-2">
            {desc.items.map((item) => {
              const isVisible = visibleItems[desc.name + '-' + item] !== false;
              if (!isVisible) return null;
              return (
                <Checkbox
                  key={item}
                  label={item}
                  name={desc.name}
                  value={item}
                  checked={
                    Array.isArray(formData[desc.name]) &&
                    (formData[desc.name] as string[]).includes(item)
                  }
                  onChange={(e) => {
                    const updatedValues = e.target.checked
                      ? [...(formData[desc.name] as string[]), item]
                      : (formData[desc.name] as string[]).filter((v) => v !== item);
                    handleInputChange(desc.name, updatedValues);
                  }}
                />
              );
            })}
          </div>
        );
      case 'text':
        return (
          <TextArea
            label=""
            name={desc.name}
            value={formData[desc.name] as string}
            onChange={(e) => handleInputChange(desc.name, e.target.value)}
            placeholder={desc.placeholder || ''}
            className="w-full rounded-10 border border-black-g px-4 py-2"
            rows={4}
          />
        );
      case 'input':
        return (
          <input
            type="text"
            name={desc.name}
            value={formData[desc.name] || ''}
            onChange={(e) => handleInputChange(desc.name, e.target.value)}
            placeholder={desc.placeholder || ''}
            className="w-full rounded-10 border border-black-g px-4 py-2"
          />
        );
      case 'toggle':
        return (
          <Toggle
            description={{
              name: desc.name,
              items: desc.items,
              is_required: desc.is_required,
            }}
            value={(formData[desc.name] as string[]) || []}
            onChange={(name, value) => {
              if (desc.max_selections && value.length > desc.max_selections) {
                const limitedValue = value.slice(0, desc.max_selections);
                handleInputChange(name, limitedValue);
              } else {
                handleInputChange(name, value);
              }
            }}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="mb-6 w-[672px] rounded-10 bg-black-0 p-6 shadow-md">
      <div className="mb-6">
        <PageTitle>{menuData.name}</PageTitle>
      </div>
      <form onSubmit={handleSubmit}>
        {menuData.menu_descriptions.map((desc, index) => (
          <div key={desc.id} className="mb-6">
            <h2 className="mb-2 flex items-end text-16 font-bold">
              <span>{`${index + 1}. ${desc.name}`}</span>
              {(desc.type === 'multiselect' || desc.type === 'toggle') && desc.max_selections && (
                <span className="ml-2 rounded bg-darkBlue-1 px-1.5 text-14 font-normal text-black-g">
                  最大{desc.max_selections}個まで
                </span>
              )}
            </h2>
            <div className="ml-4">
              {renderField(desc)}
              {errors[desc.name] && (
                <p className="mt-1 text-sm text-red-500">{errors[desc.name]}</p>
              )}
            </div>
          </div>
        ))}
        <div className="mt-6 flex justify-center ">
          <Button label="生成する" type="submit" className="font-bold" />
        </div>
      </form>
    </div>
  );
};

export default GenerateForm;
