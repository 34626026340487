import React, { useState } from 'react';
import Checkbox from './Checkbox';

interface ToggleItem {
  name: string;
  items: string[] | ToggleItem[];
}

interface MenuDescription {
  name: string;
  items: string[] | ToggleItem[];
  is_required: boolean;
}

interface ToggleProps {
  description: MenuDescription;
  value: string[];
  onChange: (name: string, value: string[]) => void;
}

const Toggle: React.FC<ToggleProps> = ({ description, value, onChange }) => {
  const [expandedItems, setExpandedItems] = useState<Record<string, Record<string, boolean>>>({});

  const handleChange = (item: string, isChecked: boolean) => {
    let updatedValue;
    if (isChecked) {
      updatedValue = [...value, item];
    } else {
      updatedValue = value.filter((v) => v !== item);
    }
    onChange(description.name, updatedValue);
  };

  const toggleExpand = (categoryName: string, itemName: string) => {
    setExpandedItems((prev) => ({
      ...prev,
      [categoryName]: { ...prev[categoryName], [itemName]: !prev[categoryName]?.[itemName] },
    }));
  };

  const renderItems = (
    items: ToggleItem[] | string[],
    parentName: string,
    categoryName: string
  ) => {
    return items.map((item) => {
      if (typeof item === 'string') {
        return (
          <Checkbox
            key={`${parentName}-${item}`}
            label={item}
            name={description.name}
            value={item}
            checked={value.includes(item)}
            onChange={(e) => handleChange(item, e.target.checked)}
          />
        );
      } else {
        const isExpanded = expandedItems[categoryName]?.[item.name] ?? false;
        return (
          <div key={`${parentName}_${item.name}`} className="mb-2">
            <div
              className="flex cursor-pointer items-center"
              onClick={() => toggleExpand(categoryName, item.name)}
            >
              <span className="mr-2 inline-block w-4 text-center text-xs">
                {isExpanded ? '▼' : '▶'}
              </span>
              <h4>{item.name}</h4>
            </div>
            {isExpanded && (
              <div className="ml-4 mt-2 pl-2">
                {renderItems(item.items, item.name, categoryName)}
              </div>
            )}
          </div>
        );
      }
    });
  };

  const isToggleItem = (item: string | ToggleItem): item is ToggleItem => {
    return typeof item === 'object' && 'name' in item && 'items' in item;
  };

  return (
    <div className="space-y-4 text-14 text-black-g">
      {description.items.map((category) => {
        if (isToggleItem(category)) {
          const isExpanded = expandedItems[category.name]?.[category.name] ?? false;
          return (
            <div key={category.name} className="mb-4">
              <div
                className="flex cursor-pointer items-center"
                onClick={() => toggleExpand(category.name, category.name)}
              >
                <span className="mr-2 inline-block w-4 text-center text-xs">
                  {isExpanded ? '▼' : '▶'}
                </span>
                <h3>{category.name}</h3>
              </div>
              {isExpanded && (
                <div className="ml-4 mt-2 pl-2">
                  {renderItems(category.items, category.name, category.name)}
                </div>
              )}
            </div>
          );
        }
        return null;
      })}
    </div>
  );
};

export default Toggle;
